import {useQuery, QueryClient} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {ProductDto} from '@/models';
import {read} from '@/services/api';

export async function getLabel(setId: string): Promise<ProductDto | null> {
  let data = null;

  if (!setId) return data;

  try {
    data = await read<ProductDto>('labels', {resourceId: setId}, {}, 'nextApi');
  } catch (e) {
    console.error(e);
  }

  return data;
}

export function useLabel(setId: string, options = {}) {
  return useQuery<ProductDto | null>({
    queryKey: ['label', setId],
    queryFn: () => getLabel(setId),
    ...options,
  });
}

export function getLabelPrefetchQuery(
  setId: string,
  bypassServer: boolean = false,
): IPrefetchedQuery {
  return {
    queryKey: ['label', setId, bypassServer],
    getFn: async () => await getLabel(setId),
  };
}

export async function fetchQueryLabel(setId: string, queryClient: QueryClient) {
  return await queryClient.fetchQuery({
    queryKey: ['label', setId],
    queryFn: () => getLabel(setId),
  });
}
