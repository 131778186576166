// 3rd party css should be imported first so our styles will take precedence over them
import '@prescriberpoint/ui/index.css';
import {
  QueryClientProvider,
  QueryClient,
  HydrationBoundary,
} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {NextPage} from 'next';
import type {AppContext, AppProps} from 'next/app';
// eslint-disable-next-line import/order
import App from 'next/app';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {ReactNode, useState, useEffect, ReactElement} from 'react';
import {CookiesProvider, useCookies} from 'react-cookie';
import Cookies from 'universal-cookie';
import {useEffectOnce} from 'usehooks-ts';
import {ErrorBoundary} from '@/components';
import HoneypotInput from '@/components/HoneypotInput';
import ToastContainerHolder from '@/components/ToastContainer';
import {
  COOKIE_CONSENT_COOKIE,
  IS_PRODUCTION,
  NPI_COOKIE,
  SPLIT_USER_COOKIE,
} from '@/constants/global';
import {
  FlagsProvider,
  DrugInteractionsProvider,
  ToastContextProvider,
  FlagsType,
} from '@/context';
import {AuthProvider} from '@/context/AuthProvider';
import {LoadingProvider} from '@/context/LoadingContext';
import {UserAgentProvider} from '@/context/UserAgentContext';
import {useClarity} from '@/hooks/useClarity';
import useClientJSFingerprint from '@/hooks/useClientJSFingerprint';
import {MainLayout} from '@/layouts';
import '@/public/styles.css';
import '../styles/globals.css';
import '../styles/fonts.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '../styles/toast.css';
import featureFlags from '@/services/featureFlags';
import {pageView} from '@/utils/gtm';
import {parse} from '@/utils/userAgent';

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  flags: FlagsType;
  uaString: string;
  cookies: Cookies;
};

const MyApp = ({
  Component,
  pageProps,
  flags,
  uaString,
  cookies: universalCookies,
}: AppPropsWithLayout) => {
  const [cookies, _setCookie, removeCookie] = useCookies([
    SPLIT_USER_COOKIE,
    COOKIE_CONSENT_COOKIE,
    NPI_COOKIE,
  ]);
  useClientJSFingerprint();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 8 * 60 * 60 * 1000, // 8 hours
            refetchOnWindowFocus: false,
            placeholderData: true,
          },
        },
      }),
  );

  const getLayout =
    Component.getLayout ||
    ((page: ReactNode) => <MainLayout>{page}</MainLayout>);

  const router = useRouter();

  // Fire pageview with current url when page first loads
  useEffectOnce(() => {
    pageView(window.location.href);
  });

  useEffect(() => {
    router.events.on('routeChangeComplete', pageView);
    return () => {
      router.events.off('routeChangeComplete', pageView);
    };
  }, [router.events]);

  const {dehydratedState = null} = pageProps;

  const isSSR = typeof window === 'undefined';

  useClarity();

  useEffect(() => {
    if (cookies[COOKIE_CONSENT_COOKIE] === 'false') {
      removeCookie(SPLIT_USER_COOKIE);
    } else if (IS_PRODUCTION) {
      // set consent if the user hasn't revoked it
      //@ts-ignore
      window.clarity?.('consent');
    }
  }, [cookies, removeCookie]);

  useEffect(() => {
    if (isSSR && dehydratedState) {
      queryClient.clear();
    }
  }, [isSSR, dehydratedState, queryClient]);

  let ua;
  if (uaString) {
    ua = parse(uaString);
  } else if (typeof window !== 'undefined') {
    ua = parse(window.navigator.userAgent);
  }

  // to hide nextjs error overlay (for cypress testing)
  const noOverlayWorkaroundScript = `
  window.addEventListener('error', event => {
    event.stopImmediatePropagation()
  })

  window.addEventListener('unhandledrejection', event => {
    event.stopImmediatePropagation()
  })
`;

  if (Component.name === 'PerformanceTestPage') {
    return <Component {...pageProps} />;
  }

  return (
    <FlagsProvider flags={flags}>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={dehydratedState}>
          <CookiesProvider cookies={new Cookies(universalCookies)}>
            <Head>
              <title>PrescriberPoint</title>
              <meta charSet='utf-8' />
              <meta
                name='description'
                content='Unlocking value in the Healthcare Provider and Pharma relationship. Enabling you to interact with Pharma on your own terms.'
                key='description'
              />
              <meta
                name='viewport'
                content='width=device-width, initial-scale=1, user-scalable=no, shrink-to-fit=no'
              />
            </Head>
            {process.env.NEXT_PUBLIC_DISABLE_ERROR_OVERLAY && (
              <script
                async
                dangerouslySetInnerHTML={{
                  __html: noOverlayWorkaroundScript,
                }}
              />
            )}

            <UserAgentProvider userAgent={ua}>
              <ErrorBoundary>
                <AuthProvider>
                  <DrugInteractionsProvider>
                    <ToastContextProvider>
                      <LoadingProvider>
                        {getLayout(
                          <>
                            <Component
                              {...{
                                ...pageProps,
                                ...dehydratedState,
                              }}
                            />
                            <ToastContainerHolder />
                          </>,
                        )}
                        <HoneypotInput />
                      </LoadingProvider>
                    </ToastContextProvider>
                  </DrugInteractionsProvider>
                </AuthProvider>
              </ErrorBoundary>
            </UserAgentProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </CookiesProvider>
        </HydrationBoundary>
      </QueryClientProvider>
    </FlagsProvider>
  );
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  const {req} = appContext.ctx;
  //@ts-ignore
  const cookies = req?.cookies || {};

  const flags = await featureFlags.fetchFlags();

  const uaString = appContext?.ctx?.req?.headers['user-agent'];
  return {
    ...appProps,
    flags,
    uaString,
    cookies,
  };
};

export default MyApp;
